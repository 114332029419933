const sendAnalytics = (goalName: string ) => {  

  if (typeof window.ym === 'function') {
    window.ym(92511690, 'reachGoal', goalName);
  } 

  if (window.VK && typeof window.VK.Retargeting?.Event === 'function') {
    window.VK.Retargeting.Event(goalName);
  } 

  if (Array.isArray(window._tmr)) {
    window._tmr.push({ type: 'reachGoal', id: 3620234, goal: goalName });
  }

};

export { sendAnalytics };
